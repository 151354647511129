/**jsx @jsx */
import React, { useEffect, useState } from 'react'
import { Box, Flex } from 'theme-ui'

import Text from '~/components/IntlComponents/Text'
import Star from '~/assets/images/icons/star.svg'
const GraphBar = ({ bar, yAxis, height, isMobileViewport, gridGap }) => {
  const { company, product, featured = false } = bar

  const [numNoodles, setNumNoodles] = useState(0)

  useEffect(() => {
    let noodleHeight = isMobileViewport ? 17.1 : 48.8
    const barHeight = gridGap * yAxis.indexOf(bar.yValue)
    const numberOfNoodles = parseInt(barHeight / noodleHeight)
    setNumNoodles(numberOfNoodles - 1)
  }, [height, bar, yAxis, gridGap, isMobileViewport])

  return (
    <Box
      sx={{
        width: ['42.5px', '115px'],
        backgroundColor: featured ? 'grape' : 'governorBay',
        color: featured ? 'grape' : 'governorBay',
        borderRadius: ['6px 6px 0 0', '10px 10px 0 0'],
        height: height,
        transition: 'height 0.25s ease',
        position: 'relative',
      }}
    >
      {featured && (
        <Flex
          sx={{
            padding: ['0 2px 3px', '0 5px 3px'],
            width: '100%',
            height: '100%',
            flexDirection: 'column',
            justifyContent: 'space-between',
            img: { width: '100%' },
            zIndex: 1,
            position: 'relative',
          }}
        >
          {[...new Array(numNoodles)].map((_n, i) => (
            <img
              src="/images/graph-noodle.png"
              key={`${company}-noodle-${i}`}
              alt="noodle"
            />
          ))}
          <img src="/images/graph-noodle.png" loading="eager" alt="noodle" />
        </Flex>
      )}
      <Box
        sx={{
          position: 'absolute',
          bottom: ['-14px', '-30px'],
          left: '50%',
          transform: 'translate(-50%, 100%)',
          textAlign: 'center',
          lineHeight: '120%',
          fontWeight: featured ? 'heading' : 'body',
          fontSize: ['10px', 3],
          width: ['210%', '200%'],
        }}
      >
        <Text as="div">{company}</Text>
        <Text as="div">{product}</Text>
      </Box>
      {featured && (
        <Flex
          sx={{
            position: 'absolute',
            top: ['-5px', '-14px'],
            left: '50%',
            transform: 'translate(-50%, -100%)',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 1,
            img: {
              position: 'absolute',
              width: ['21px', '50px'],
              height: 'auto',
            },
            svg: {
              animation: 'rotate 10s linear infinite',
              width: ['43px', '103px'],
              height: 'auto',
              path: { strokeWidth: ['5px', '2px'] },
            },
          }}
        >
          <Star />
          <img src="/images/logo.png" loading="eager" alt="Goodles Logo" />
        </Flex>
      )}
    </Box>
  )
}
export default GraphBar
